// extracted by mini-css-extract-plugin
export var ReplayIntroSectionEnComponent = "ReplayIntroSectionEn-module--ReplayIntroSectionEnComponent--E-tzc";
export var otherLanguage = "ReplayIntroSectionEn-module--otherLanguage--WoQFw";
export var wrapper = "ReplayIntroSectionEn-module--wrapper--hJ9zG";
export var left__wrapper = "ReplayIntroSectionEn-module--left__wrapper--e8PwT";
export var navButtons = "ReplayIntroSectionEn-module--navButtons--A0Kyz";
export var speakers_button = "ReplayIntroSectionEn-module--speakers_button--eEYiC";
export var right__wrapper = "ReplayIntroSectionEn-module--right__wrapper--lnoKl";
export var virtual_event = "ReplayIntroSectionEn-module--virtual_event--UB+Zq";
export var free_event = "ReplayIntroSectionEn-module--free_event--QcH80";
export var title_precisedate = "ReplayIntroSectionEn-module--title_precisedate--cW8mB";
export var title_description = "ReplayIntroSectionEn-module--title_description--JAqxE";
export var tags = "ReplayIntroSectionEn-module--tags--foe7r";