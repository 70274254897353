import { graphql, useStaticQuery } from "gatsby"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"

import ashley from "@/assets/images/replays/BLOG - Replays US Ahsley Faus.jpg"
import alison from "@/assets/images/replays/BLOG - Replays US Alison Coleman.jpg"
import alli from "@/assets/images/replays/BLOG - Replays US Alli Tunell.jpg"
import anthony from "@/assets/images/replays/BLOG - Replays US Anthony Kennada.jpg"
import jimmy from "@/assets/images/replays/BLOG - Replays US Jimmy Daly.jpg"
import melissa from "@/assets/images/replays/BLOG - Replays US Melissa Rosenthal.jpg"
import nate from "@/assets/images/replays/BLOG - Replays US Nate Martins.jpg"
import thibaut from "@/assets/images/replays/BLOG - Replays US Thibaut Machet.jpg"
import useWindowSize from "@/hooks/UseWindowSize"

import * as styles from "./ReplaySectionEn.module.scss"

if (typeof window !== `undefined`) {
  gsap.registerPlugin(ScrollTrigger)
  gsap.core.globals("ScrollTrigger", ScrollTrigger)
}

const ReplaySectionEn = ({ spearkersSectionRef }) => {
  const sectionRef = useRef(null)
  const titleRef = useRef(null)
  const lightMaskRef = useRef(null)
  const [lightMaskSize, setLightMaskSize] = useState([0, 0])
  const intl = useIntl()

  const size = useWindowSize()

  useEffect(() => {
    setLightMaskSize([
      sectionRef.current.clientWidth,
      sectionRef.current.clientHeight,
    ])
    const titlePosition = titleRef.current.getBoundingClientRect()
    gsap.to(lightMaskRef.current, {
      r: 100,
      attr: {
        cx: titlePosition.x + titleRef.current.clientWidth / 2,
        cy: 200,
      },
      opacity: 0,
      duration: 0,
    })

    ScrollTrigger.create({
      trigger: sectionRef.current,
      start: "top top",
      end: "top top",
      // markers: true,
      onEnter: () => {
        let tl = gsap.timeline()
        tl.to(lightMaskRef.current, {
          r: 400,
          opacity: 1,
          duration: 0.2,
        })
        tl.to(lightMaskRef.current, {
          r: 200,
          duration: 0.1,
        })
        tl.to(lightMaskRef.current, {
          r: 400,
          duration: 0.1,
        })
        tl.to(lightMaskRef.current, {
          r: 200,
          duration: 0.1,
        })
        tl.to(lightMaskRef.current, {
          r: 400,
          duration: 0.1,
        })
      },
      onLeaveBack: () => {
        gsap.to(lightMaskRef.current, {
          r: 100,
          opacity: 0,
          duration: 0.2,
        })
      },
    })
  }, [])

  useEffect(() => {
    setLightMaskSize([
      sectionRef.current.clientWidth,
      sectionRef.current.clientHeight,
    ])
  }, [size])

  return (
    <div className={styles.ReplaySectionEnComponent} ref={sectionRef}>
      <div className={styles.wrapper} ref={spearkersSectionRef}>
        <div className={styles.title__wrapper}>
          <h2 className={styles.title} ref={titleRef}>
            <FormattedMessage id="replay2022" />
          </h2>
        </div>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.speakerCardWrapper}>
          <a
            href="https://www.youtube.com/watch?v=d2H_7r2ybZ4"
            target="_blank"
            className={styles.card}
            rel="noreferrer"
          >
            <img src={alison} alt="Alison Coleman" />
          </a>
          <a
            href="https://www.youtube.com/watch?v=PdFsdIYjROc"
            target="_blank"
            className={styles.card}
            rel="noreferrer"
          >
            <img src={melissa} alt="Melissa Rosenthal" />
          </a>
          <a
            href="https://www.youtube.com/watch?v=lG9kvbCoGMA"
            target="_blank"
            className={styles.card}
            rel="noreferrer"
          >
            <img src={anthony} alt="Anthony Kennada" />
          </a>
          <a
            href="https://www.youtube.com/watch?v=XnCTTcGziPQ"
            target="_blank"
            className={styles.card}
            rel="noreferrer"
          >
            <img src={jimmy} alt="Jimmy Daly" />
          </a>
          <a
            href="https://www.youtube.com/watch?v=qy6EXk7FvIE"
            target="_blank"
            className={styles.card}
            rel="noreferrer"
          >
            <img src={thibaut} alt="Thibaut Machet" />
          </a>
          <a
            href="https://www.youtube.com/watch?v=LJv8ymj4kBc"
            target="_blank"
            className={styles.card}
            rel="noreferrer"
          >
            <img src={nate} alt="Nate Martins" />
          </a>
          <a
            href="https://www.youtube.com/watch?v=SmpFSwfU-R4"
            target="_blank"
            className={styles.card}
            rel="noreferrer"
          >
            <img src={ashley} alt="Ashley Faus" />
          </a>
          <a
            href="https://www.youtube.com/watch?v=wE7ZGanK1AM"
            target="_blank"
            className={styles.card}
            rel="noreferrer"
          >
            <img src={alli} alt="Alli Tunell" />
          </a>
        </div>
      </div>
    </div>
  )
}

export default ReplaySectionEn

ReplaySectionEn.propTypes = {
  speakerImages: PropTypes.array,
  spearkersSectionRef: PropTypes.object,
}

// SpeakerSection.defaultProps = {}
