import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import PropTypes from "prop-types"
import React, { useEffect, useRef } from "react"
import { FormattedMessage, useIntl } from "react-intl"

import Loader from "@/components/Loader/Loader"
import ChevronDown from "@/svgs/ChevronDown/ChevronDown"
import HeroLogoEn from "@/svgs/HeroLogoEn/HeroLogoEn"
import HeroLogoFr from "@/svgs/HeroLogoFr/HeroLogoFr"
import RetroIcon from "@/svgs/RetroIcon/RetroIcon"

import * as styles from "./ReplayIntroSectionEn.module.scss"

if (typeof window !== `undefined`) {
  gsap.registerPlugin(ScrollTrigger)
  gsap.core.globals("ScrollTrigger", ScrollTrigger)
}

const ReplayIntroSectionEn = ({ scrolltoConf }) => {
  const loader = useRef(null)
  const sectionRef = useRef(null)
  const voirconfbutton = useRef(null)
  const intl = useIntl()

  useEffect(() => {
    //calculate radius intro
    let radiusmax = sectionRef.current.clientHeight / 2 - 40
    let radiusGradient = 600
    if (radiusGradient >= radiusmax) {
      radiusGradient = radiusmax
    }

    //timeline Intro Animation
    let tl = gsap.timeline()
    // tl.to(bottomNeons.current, { width: bottomNeonWidth, duration: 0 })
    tl.to(loader.current, {
      opacity: 0,
      duration: 0.4,
    })
    tl.to(loader.current, {
      display: "none",
      duration: 0,
    })

    //opacity Voir Conferenciers
    const op_tl = gsap.timeline({
      scrollTrigger: {
        trigger: sectionRef.current,
        start: "center top",
        end: "bottom top",
        scrub: true,
        // markers: true,
      },
    })
    op_tl.to(voirconfbutton.current, { opacity: 0 })
  }, [])

  return (
    <>
      <Loader loader={loader} />
      <div className={styles.ReplayIntroSectionEnComponent} ref={sectionRef}>
        <div className={styles.wrapper}>
          <div className={styles.left__wrapper}>
            {intl.locale === "fr" ? <HeroLogoFr /> : <HeroLogoEn />}
            <div className={styles.navButtons}>
              <div
                className={styles.speakers_button}
                onClick={scrolltoConf}
                role="presentation"
              >
                <RetroIcon />
                <p>
                  <FormattedMessage id="replay-discover-speakers" />
                </p>
                <ChevronDown />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ReplayIntroSectionEn

ReplayIntroSectionEn.propTypes = {
  formRef: PropTypes.object,
  scrolltoConf: PropTypes.func,
}
